import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "specialties", "positions", "desiredCategories", "commissions" ]

  connect() {
    StimulusReflex.register(this)

    this.toggleSpecialties( !this.data.get('education-level')  || this.data.get('education-level') < 3 )

    this.togglePositions( !this.data.get('education-level')  || this.data.get('education-level') == 3 )
  }

  toggleSpecialties(isSpecialty) {
    if (isSpecialty) {
      $(this.specialtiesTarget).show()
    } else {
      $(this.specialtiesTarget).hide()
      $(this.specialtiesTarget).find('select').val('')
    }
  }

  togglePositions(isPosition) {
    if (isPosition) {
      $(this.positionsTarget).show()
    } else {
      $(this.positionsTarget).hide()
      $(this.positionsTarget).find('select').val('')
    }
  }

  loadDependencies(event) {
    let educationLevelId = $(event.target).val()
    if (!educationLevelId) { return }

    let isSpecialty = (educationLevelId === '1' || educationLevelId === '2')
    let isPosition  = !isSpecialty
    this.toggleSpecialties(isSpecialty)
    this.togglePositions(isPosition)
    if (isSpecialty) { this.loadSpecialties(educationLevelId) }
    if (isPosition)  { this.loadPositions(educationLevelId)   }
  }

  loadFilterDependencies(event) {
    let educationLevelId = $(event.target).val()

    let isSpecialty = (educationLevelId == '' || educationLevelId === '1' || educationLevelId === '2')
    let isPosition  = (educationLevelId == '' || !isSpecialty)
    this.toggleSpecialties(isSpecialty)
    this.togglePositions(isPosition)
    if (isSpecialty) { this.loadFilterSpecialties(educationLevelId) }
    if (isPosition)  { this.loadFilterPositions(educationLevelId)   }
  }

  loadSpecialties(educationLevelId) {
    if (!educationLevelId) { return }

    $('#specialties-details select').attr('data-controller', '')
    this.stimulate('Test::Request#specialties_details', { serializeForm: false }, educationLevelId)
  }

  loadPositions(educationLevelId) {
    if (!educationLevelId) { return }

    $('#positions-details select').attr('data-controller', '')
    this.stimulate('Test::Request#positions_details', { serializeForm: false }, educationLevelId)
  }

  loadDesiredCategories(event) {
    let id = $(event.target).val()
    if (!id) { return }

    $('#test-request-desired-categories-details select').attr('data-controller', '')

    this.stimulate('Test::Request#desired_categories_details', { serializeForm: false }, id)
  }

  loadCommissions(event) {
    let id = $(event.target).val()
    // if (!id) { return }

    $('#test-request-commissions-details select').attr('data-controller', '')

    this.stimulate('Test::Request#commissions_details', { serializeForm: false }, id)
  }

  loadFilterSpecialties(educationLevelId) {

    $('#filter-specialties-details select').attr('data-controller', '')
    this.stimulate('Test::Request#filter_specialties_details', { serializeForm: false }, educationLevelId)
  }

  loadFilterPositions(educationLevelId) {

    $('#filter-positions-details select').attr('data-controller', '')
    this.stimulate('Test::Request#filter_positions_details', { serializeForm: false }, educationLevelId)
  }
}
