import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
    // this.do_scroll_down()
  }

  do_scroll_down(event) {
    $(function () {
      window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    })
  }
}
