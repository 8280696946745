import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "specialties", "positions", 'commissionIds' ]

  connect() {
    StimulusReflex.register(this)
    const is_control = this.data.has('test-kind') && (this.data.get('test-kind') === 'control_test')
    const is_sub_group = this.data.has('agreement-logic') && (this.data.get('agreement-logic') === 'sub_group')

    this.toggleСommission((is_control || is_sub_group))

    this.toggleSpecialties( !this.data.get('education-level') || this.data.get('education-level') < 3 )

    this.togglePositions( !this.data.get('education-level') || this.data.get('education-level') == 3 )
  }

  doTestKindSelect(event) {
    const is_control = event.target.value === 'control_test'
    const is_sub_group = this.data.has('agreement-logic') && (this.data.get('agreement-logic') === 'sub_group')
    this.toggleСommission( (is_control || is_sub_group))
  }

  toggleSpecialties(isSpecialty) {
    if (isSpecialty) {
      $(this.specialtiesTarget).show()
    } else {
      $(this.specialtiesTarget).hide()
      $(this.specialtiesTarget).find('select').val('')
    }
  }

  togglePositions(isPosition) {
    if (isPosition) {
      $(this.positionsTarget).show()
    } else {
      $(this.positionsTarget).hide()
      $(this.positionsTarget).find('select').val('')
    }
  }

  loadDependencies(event) {
    let educationLevelId = $(event.target).val()
    if (!educationLevelId) { return }

    let isSpecialty = (educationLevelId == '' || educationLevelId === '1' || educationLevelId === '2')
    let isPosition  = (educationLevelId == '' || !isSpecialty)
    this.toggleSpecialties(isSpecialty)
    this.togglePositions(isPosition)
    if (isSpecialty) { this.loadSpecialties(educationLevelId) }
    if (isPosition)  { this.loadPositions(educationLevelId)   }
  }

  loadFilterDependencies(event) {
    let educationLevelId = $(event.target).val()

    let isSpecialty = (educationLevelId == '' || educationLevelId === '1' || educationLevelId === '2')
    let isPosition  = (educationLevelId == '' || !isSpecialty)
    this.toggleSpecialties(isSpecialty)
    this.togglePositions(isPosition)
    if (isSpecialty) { this.loadFilterSpecialties(educationLevelId) }
    if (isPosition)  { this.loadFilterPositions(educationLevelId)   }
  }

  loadSpecialties(educationLevelId) {
    $('#specialties-details select').attr('data-controller', '')
    this.stimulate('Test::QuestionsGroup#specialties_details', { serializeForm: false }, educationLevelId)
  }

  loadPositions(educationLevelId) {
    if (!educationLevelId) { return }

    $('#positions-details select').attr('data-controller', '')
    this.stimulate('Test::QuestionsGroup#positions_details', { serializeForm: false }, educationLevelId)
  }

  loadFilterSpecialties(educationLevelId) {

    $('#filter-specialties-details select').attr('data-controller', '')
    this.stimulate('Test::QuestionsGroup#filter_specialties_details', { serializeForm: false }, educationLevelId)
  }

  loadFilterPositions(educationLevelId) {

    $('#filter-positions-details select').attr('data-controller', '')
    this.stimulate('Test::QuestionsGroup#filter_positions_details', { serializeForm: false }, educationLevelId)
  }

  toggleСommission(isCommission) {
    if (!this.hasCommissionIdsTarget) { return }

    if (isCommission) {
      $(this.commissionIdsTarget).show()
    } else {
      $(this.commissionIdsTarget).hide()
    }
  }
}
