import { Controller } from "stimulus"
import $ from "jquery";


export default class extends Controller {
  static targets = [ "submitButton"]

  connect() {
    $('.test_request_question_answer_ids').find('legend').hide();
    // this.data.has('answers') && this.data.get('answers')
    this.toggleBtn(
      false
    )
  }

  submitForm(e) {
    $(this.submitButtonTarget).click()
  }

  showHideSubmit(event) {
    let value = $('input[name*="test_request_question[answer_ids]"]:checked').length;
    this.toggleBtn(value)
  }

  toggleBtn(showBtn) {
    if (showBtn) {
      $(this.submitButtonTarget).prop('disabled', false)
    } else {
      $(this.submitButtonTarget).prop('disabled', true)
    }
  }
}
