import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = ["commission", "education", "specialties", "positions", "region"]

  connect() {
    this.toggleСommission(
      this.data.has('roles') && (
        this.data.get('roles') === 'operator' ||
        this.data.get('roles') === 'operator_and_author' ||
        this.data.get('roles') === 'author' ||
        this.data.get('roles') === 'chairman'
      )
    )

    this.toggleRegion(
      this.data.has('roles') && (
        this.data.get('roles') === 'regional_operator'
      )
    )

    this.toggleEducation(
      this.data.has('roles') && (
        this.data.get('roles') === 'author' ||
        this.data.get('roles') === 'chairman' ||
        this.data.get('roles') === 'operator_and_author'
      )
    )

    this.toggleSpecialties(
      this.data.has('roles') && (
        this.data.get('roles') === 'author' ||
        this.data.get('roles') === 'chairman' ||
        this.data.get('roles') === 'operator_and_author'
      ) && (
        this.data.get('education') == 1 ||
        this.data.get('education') == 2
      )
    )

    this.togglePositions(
      this.data.has('roles') && (
        this.data.get('roles') === 'author' ||
        this.data.get('roles') === 'chairman' ||
        this.data.get('roles') === 'operator_and_author'
      ) && (
        this.data.get('education') == 3
      )
    )

    StimulusReflex.register(this)
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleСommission( roleId === '6' || roleId === '7' || roleId === '3' || roleId === '4')
    this.toggleRegion( roleId === '8')
    this.toggleEducation(roleId === '3' || roleId === '4' || roleId === '7')
    this.toggleSpecialties(roleId === '3' || roleId === '4' || roleId === '7')
  }

  toggleСommission(isCommission) {
    if (isCommission) {
      $(this.commissionTarget).show()
    } else {
      $(this.commissionTarget).hide()
    }
  }

  toggleRegion(isRegion) {
    if (isRegion) {
      $(this.regionTarget).show()
    } else {
      $(this.regionTarget).hide()
    }
  }

  toggleEducation(isEducation) {
    if (isEducation) {
      $(this.educationTarget).show()
    } else {
      $(this.educationTarget).hide()
    }
  }

  toggleSpecialties(isSpecialty) {
    if (isSpecialty) {
      $(this.specialtiesTarget).show()
    } else {
      $(this.specialtiesTarget).hide()
    }
  }

  togglePositions(isPosition) {
    if (isPosition) {
      $(this.positionsTarget).show()
    } else {
      $(this.positionsTarget).hide()
    }
  }

  loadSpecialtiesOrPositions(event) {
    let id = $(event.target).val()
    if (!id) { return }

    $('#user-specialties-details select').attr('data-controller', '')
    $('#user-positions-details select').attr('data-controller', '')

    this.stimulate('User#specialties_positions_details', { serializeForm: false }, id)

    this.toggleSpecialties(id === '1' || id === '2')
    this.togglePositions(id === '3')
  }

}
